<template>
  <div class="body">
    <Header titleurl="recruit"></Header>
    <div>
      <div class="head_red">
        {{fullName }}
      </div>
      <div class="center_table">
        <el-table
          :data="cmpJobLists"
          @row-click="handleEdit"
          style="width: 100%"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column
            prop="job"
            label="招聘岗位"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span class="position_red">{{ scope.row.positionName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="薪资水平">
            <template slot-scope="scope">
              <span class="salary">{{
                scope.row.salary === '面议' || scope.row.salary === ''
                  ? '面议'
                  : scope.row.salary
                  ? scope.row.salary + '/月'
                  : ''
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="workCity" label="工作地点"></el-table-column>
          <el-table-column prop="education" label="学历"></el-table-column>
          <el-table-column prop="workExp" label="工作经验"></el-table-column>
          <el-table-column prop="num" label="招聘人数">
            <template slot-scope="scope">
              <span>{{
                scope.row.num === null
                  ? ''
                  : scope.row.num
                  ? scope.row.num + '人'
                  : ''
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="更新时间">
            <template slot-scope="scope">
              <span>{{
                scope.row.updateDate === null
                  ? ''
                  : scope.row.updateDate
                  ? scope.row.updateDate.substring(0, 10)
                  : ''
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="posNum"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import { cmpdetail, hrcompanyinfo } from '../../api/position';

export default {
  name: 'recruitposition',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      reeCid: null,
      fullName: '',
      posNum: null,
      posiVos: [],
      currentPage: 1,
      pagesize: 10,
      // 公司列表数据
      cmpJobLists: [],
      // 获取公司职位列表数据
      bean: {
        cid: 0,
        keyword: '',
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.reeCid = this.$route.query.reeCid;
    this.bean.cid = this.reeCid;
    let bean = this.bean;
    this.cmpdetails(bean);
    this.getHrcompanyinfo(this.reeCid);
  },
  methods: {
    //公司明细
    cmpdetails(id) {
      let bean = this.bean;
      cmpdetail(bean).then((res) => {
        console.log(res);
        if (res.data.code === 1) {
          this.result = res.data.result;
          this.cmpJobLists = res.data.result.list;
          this.posNum = res.data.result.totalCount;
          this.currentPage = res.data.result.currPageIndex;
        }
      });
    },
    // 获取公司信息
    async getHrcompanyinfo(id) {
      const { data: res } = await hrcompanyinfo(id);
      this.fullName = res.result.fullName;
    },
    //点击行获取信息
    handleEdit(val) {
      this.$router.push({
        path: '/recruit/positiondetail',
        query: { id: val.id },
      });
      console.log(val)
    },
    //每页几条
    handleSizeChange(pagesize) {
      this.bean.pageSize = pagesize;
      let bean = this.bean;
      this.cmpdetails(bean);
    },
    //点击获取第几页
    handleCurrentChange(currentPage) {
      this.bean.pageIndex = currentPage;
      let bean = this.bean;
      this.cmpdetails(bean);
    },
  },
};
</script>

<style scoped>
.head_red {
  background-color: red;
  font-size: 30px;
  height: 88px;
  line-height: 88px;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
}
.center_table {
  width: 1140px;
  margin: 0px auto;
}
.center_table .block {
  margin: 30px 0;
  text-align: center;
}
>>> .center_table .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f2f2f2 !important;
  cursor: pointer;
}
.center_table .position_red {
  color: #ff552e;
}
.center_table .salary {
  font-weight: 700;
  color: black;
}
</style>
